import { Controller } from "@hotwired/stimulus";
import React from "react";
import * as ReactDOMClient from "react-dom/client";

export class ReactController extends Controller {
  static values = {
    props: Object,
  };
  get root() {
    if (this._root === undefined) {
      this._root = ReactDOMClient.createRoot(this.element);
    }
    return this._root;
  }

  disconnect() {
    this.root.unmount();
  }
}
