let authToken;

const JSON_HEADERS = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export default async function railsFetch(
  url: string,
  options: RequestInit | undefined
) {
  if (!authToken) {
    authToken = document
      .querySelector('meta[name="csrf-token"]')
      ?.getAttribute("content");
  }

  const authHeaders = {
    "X-CSRF-Token": authToken,
  };

  const headers = Object.assign({}, options?.headers, authHeaders);

  return await fetch(url, { ...options, headers });
}

export async function fetchJson(url: string, options: RequestInit | undefined) {
  const headers = Object.assign({}, options?.headers, JSON_HEADERS);
  return railsFetch(url, { ...options, headers });
}
